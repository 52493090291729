"use strict";

const TARIFFS = {
  "fZeaGNdaB5QZam4eVf": {
    name: "1 Booming Product",
    price: 187,
  },
  "eVag17b2t6V3fGofZk": {
    name: "5 Booming Products",
    price: 547,
  },
  "28o6qxc6xenvbq84gD": {
    name: "1 Booming Product",
    price: 547,
  },
  "28odSZeeFbbj65O14s": {
    name: "5 Booming Products",
    price: 2495,
  },
  "eVa8yFb2t7Z7am414x": {
    name: "1 Supplier Check Report",
    price: 299,
  },
  "00g7uB4E593bfGo28y": {
    name: "One Product Listing includes",
    price: 197,
  },
  "9AQ0296Md93bfGocNd": {
    name: "Three Product Listings include",
    price: 547,
  },
  "3cs2ah1rT6V30LueVo": {
    name: "Service - 1 Booming Product",
    price: 1,
  }
}

(() => {
  const { hostname, searchParams } = new URL(window.location.href);
  const idevId = searchParams.get("idev_id");
  const LOCAL_KEY = "sellerCart";

  const getId = (href) => new URL(href).pathname.split('/').pop();

  const listenBuyNow = () => {
    document.addEventListener("click", (e) => {
      const target = e.target;
      const href = target.href;
      if (target.tagName === "A" && href.includes("buy.stripe.com")) {
        e.preventDefault();
        saveDataInLocal(href);
      }
    });
  };

  const setItem = (body) => {
    if (typeof window !== "undefined") {
      window.localStorage.setItem(LOCAL_KEY, JSON.stringify(body));
    }
  };

  const saveDataInLocal = (href) => {
    const id = getId(href);
    if (id) {
      const body = {id, hostname, idevId}
      console.log("body ", body)
      setItem(body);
      // } else if (!tariff && id) {
      //   const body = { id, host, idevId }
      //   setItem(body);
      //   gtag('event', "paywall_unknown_id", {
      //     'event_category': "Paywall",
      //     'event_label': id,
      //     'transport_type': 'beacon'
      //   });
      //   console.log("body ", body)
      //   console.warn(`Error: unknown ID ${id}`)
    } else {
      console.error(`Error: ID missing`)
    }

    setTimeout(() => {
      window.location.href = href
    }, 1000);
  };

  document.addEventListener("DOMContentLoaded", listenBuyNow);
})();
